import React from 'react'
import Parser from 'html-react-parser'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import { graphql } from 'gatsby'

import Layout from '../components/Layout.js'

export default ({ data: { impressum }, location }) => {
  return (
    <Layout location={location}>
      <HelmetDatoCms seo={impressum.seoMetaTags} />
      <section className="container mt-5">
        <div className="row">
          <div className="col-12">
            {impressum.block.map((inh) => (
              <div key={inh.id} id={inh.ueberschrift.toLowerCase()}>
                <h3>{inh.ueberschrift}</h3>
                <div>{Parser(inh.text)}</div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query ImpressumQuery {
    impressum: datoCmsImpressum {
      block: inhalt {
        id
        ueberschrift
        text
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
